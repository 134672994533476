import React from "react";
import Loading from "./loading";

const Enter = (props) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents default behavior (e.g., form submission)
            props.submitForm();    // Calls the submitForm function
        }
    };

    return (
        <>
            {
                props.requestLifeCycle
                
                ?

                    <div className="banner" style={{ cursor: "pointer" }}>
                        <>
                            <Loading />
                        </>
                    </div>

                :

                <>
                    <input className="banner" type="submit" value={props.text} style={{ cursor: "pointer" }} onClick={props.submitForm} onKeyDown={handleKeyDown} />
                </>

            }
        </>
    );
}

export default Enter;