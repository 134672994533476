import React, { useRef } from "react";
import Nav from "./nav";
import Body from "./body";

const Landing = () => {
    const howRef = useRef(null);
    const offerRef = useRef(null);
    const supportRef = useRef(null);

    return (
        <>
            <Nav howRef={howRef} offerRef={offerRef} supportRef={supportRef}/>
            <Body howRef={howRef} offerRef={offerRef} supportRef={supportRef}/>
        </>
    );
}

export default Landing;