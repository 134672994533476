import React, { useState } from "react";
import Eye from "./eye";
import EyeSlash from "./eyeSlash";

const Password = (props) => {
    const [show, setShow] = useState(false);
    const illegalCharacters = [
        "\\",
        '"',
    ]

    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (!illegalCharacters.some(char => inputValue.includes(char))) {
            props.updateValue(inputValue)
        }
    }
    return (
        <>
            <div className="input-field" style={{ userSelect: "none" }}>
                <div className="top-input">
                    { props.top }
                </div>
                <input className="bottom-input" type={ show ? "text" : "password" } name="password" value={ props.value } onChange={ handleChange } required={ props.required } autoComplete={ props.autoComplete } placeholder={ props.placeholder } />
                {
                    props.password

                    ?
                        show 

                        ?
                            <div onClick={() => {setShow(false)}}>
                                <EyeSlash />
                            </div>

                        :
                            <div onClick={() => {setShow(true)}}>
                                <Eye />
                            </div>

                    :

                    <>
                    </>

                }
            </div>
        </>
    );
}

export default Password;