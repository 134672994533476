import React from "react";

const HowWorksRow = (props) => {
    return (
        <>
            <div className="how-works-row">
                { props.svg }
                <div className="header how-text">
                    { props.text }
                </div>
            </div>
        </>
    );
}

export default HowWorksRow;