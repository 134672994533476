import React from "react";
import useWindowWidth from "./useWindowWidth";

const LogoFill = () => {
    const width = useWindowWidth(615);

    return (
        <>
            <div className="logo-container" style={{ cursor: "pointer", userSelect: "none"  }} onClick={ () => {
                window.location.reload();
            } }>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                    <path fill="currentColor" stroke="currentColor" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                </svg>
                {
                    width 

                    ?
                        <div className="logo-text">
                            GoValuate
                        </div>

                    :

                        <>
                        </>
                    
                }
            </div>
        </>
    );
}

export default LogoFill;