import React from "react";

const SmallCard = (props) => {
    return (
        <>
            <div className="small-card">
                { props.svg }
                <div className="small-card-text">
                    { props.text }
                </div>
            </div>
        </>
    );
}

export default SmallCard;