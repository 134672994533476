import React from "react";
import ArrowRight from "./arrowRight";
import useWindowWidth from "./useWindowWidth";
import { useNavigate } from "react-router-dom";

const Card = (props) => {
    const navigate = useNavigate();
    const width = useWindowWidth(435);

    return (
        <>
            <div className="card">
                <div className="svg-container">
                    { props.left }
                    <ArrowRight />
                    { props.right }
                </div>
                <div className="middle-title" style={{ fontSize: width ? "20px" : "" }}>
                    { props.title }
                </div>
                <div className="card-button" onClick={() => {
                    navigate("/sign-up")
                }}>
                    { props.button }
                </div>
            </div>
        </>
    );
}

export default Card;