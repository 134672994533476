import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Envelopes from "./envelopes";
import Mailbox from "./mailbox";
import Loading from "./loading";
import Info from "./info";

const ConfirmEmailWithToken = () => {
    const { email, token } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("An error occurred");

    useEffect(() => {
        const fetchVerificationProcess = async () => {
            const url = process.env.REACT_APP_USER_API_URL + "/verify-email";

            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: email,
                    token: token
                })
            }

            try {
                setRequestLifeCycle(true);
                const response = await fetch(url, urlOptions);
                const data = await response.json();
                console.log(data)
                if (response.status === 200) {
                    setText("Success");
                    if (data.role === "professor") {
                        window.location.href = process.env.REACT_APP_INSTRUCTOR_REDIRECT_URL;
                    } else if (data.role === "student") {
                        window.location.href = process.env.REACT_APP_STUDENT_REDIRECT_URL;
                    }
                } else if (response.status === 400) {
                    setText("Verification link expired or invalid")
                } else if (response.status === 404) {
                    setText("User not found")
                } else if (response.status === 409) {
                    setText("Email address already verified")
                } else if (response.status === 207) {
                    setText("Email address verfied. Please login")
                } else {
                    setText("An error occurred")
                }
                
            } catch (error) {
                console.log(error)
            } finally {
                setRequestLifeCycle(false);
            }
        }
        
        fetchVerificationProcess();
    }, [])

    return (
        <>
            <div className="signup-container">
                <div className="add-a-course-form-container">
                    <div className="add-a-course-form-content-container">
                        <form className="form-width">
                            <div className="add-course-title">
                                Email Verification In Progress
                            </div>
                            <div className="account-settings-content">
                                <div className="required-container">
                                    Please Wait
                                </div>   
                                <div className="banner top">
                                    {
                                        requestLifeCycle 

                                        ?

                                        <>
                                            <Loading />
                                        </>

                                        :

                                        <div className="banner-info">
                                            <Info />
                                            { text }
                                        </div>

                                    }
                                </div>
                                <div className="gray-svg-container">
                                    <Mailbox checked={true}/>
                                    <Envelopes checked={true}/>
                                </div>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmEmailWithToken;