import React from "react";
import Check from "./check";
import Dash from "./dash";

const FeatureRow = (props) => {
    return (
        <>
            <div className="feature-row">
                <div className="header" style={{ width: props.width ? "50%" : "33.3%", justifyContent: "flex-start" }}>
                    { props.feature }
                </div>
                {
                    props.width

                    ?

                    <>  
                        <div className="header" style={{ width: "50%", justifyContent: "flex-end" }}>
                            { props.free ? <Check /> : "Soon"}
                        </div>
                    </>

                    :

                    <>
                        <div className="header" style={{ width: "33.3%", justifyContent: "center" }}>
                            { props.free ? <Check /> : <Dash /> }
                        </div>
                        <div className="header" style={{ width: "33.3%", justifyContent: "flex-end" }}>
                            { props.soon ? <Check /> : <Dash /> }
                        </div>
                    </>

                }
            </div>
        </>
    );
}

export default FeatureRow;