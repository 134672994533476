import React, { useState, useEffect } from "react";
import Input from "./input";
import { useNavigate } from "react-router-dom";
import Enter from "./enter";

const EnterReset = () => {
    const navigate = useNavigate();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("Reset password");
    const [form, setForm] = useState({
        primaryEmail: ""
    });

    // have a useEffect to check whether or not user is logged in and if they are then reroute them to their role-based sub domain
    
    function updatePrimaryEmail(primaryEmail) {
        setForm(prevFrom => ({
            ...prevFrom,
            primaryEmail: primaryEmail,
        }))
    }

    function submitForm(e) {
        e.preventDefault();

        for (const value in form) {
            if (form[value] === "") {
                setText("Please enter an email")
                return;
            }
        }

        // Additional email validation checks
        if (!form.primaryEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setText("Invalid email format");
            return;
        }

        startResetPasswordProcess();
        
    }

    const startResetPasswordProcess = async () => {
        const url = process.env.REACT_APP_USER_API_URL + "/create-reset-password";

        const urlOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email: form.primaryEmail })
        }

        try {
            setRequestLifeCycle(true);
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 200) {
                navigate(`/reset-password/${form.primaryEmail}/`);
            } else if (res.status === 400) {
                setText("Email address is already verified");
                navigate(`/confirm-email/${form.primaryEmail}`);
            } else if (res.status === 409) {
                setText("Please wait 15 minutes for email resend cooldown");
            } else {
                setText("Verication email failed to resend")
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error)
        } finally {    
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        setText("Reset password");
    }, [form])

    return (
        <>
            <div className="signup-container">
                <div className="add-a-course-form-container">
                    <div className="add-a-course-form-content-container">
                        <form className="form-width">
                            <div className="add-course-title">
                                Reset Password
                            </div>
                            <div className="account-settings-content">
                                <div className="required-container">
                                    All Fields Required
                                </div>   
                                <div className="banner top">
                                    Enter your account email address
                                </div>
                                <Input top={"Email Address"} require={true} value={form.primaryEmail} updateValue={updatePrimaryEmail} placeholder={"johndoe@university.edu"} autoComplete={"email"} type={"text"}/>
                                <div className="forgot-verify" style={{ justifyContent: "flex-end" }}>
                                    <span className="actions" onClick={() => {
                                        navigate("/login")
                                    }}> Log in? </span>
                                </div>
                                <Enter requestLifeCycle={requestLifeCycle} submitForm={submitForm} text={text}/>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default EnterReset;